import info from "../../info.json";

class {
    onCreate() {
        this.state = {
            navVisibility: false,
            servicesVisibility: false,
            pathname: null,
            pathnameHash: null
        };
    }
    onMount() {
        this.state.pathname = window.location.pathname;
        this.state.pathnameHash = window.location.pathname + window.location.hash;

        if (this.state.pathname === "/") {
            document.querySelectorAll(".nav-a").forEach((a) => {
                a.addEventListener("click", (e) => {
                    if (a.id === "nos-services-link") {
                        e.preventDefault();
                        document.querySelector('#nos-services').scrollIntoView({ behavior: 'smooth' });
                        setTimeout(() => {
                            window.location.hash = "#nos-services";
                            this.state.pathnameHash = window.location.pathname + window.location.hash;
                        }, 500);                    
                    } else if (a.id === "recrutement-link") {
                        e.preventDefault();
                        document.querySelector('#recrutement').scrollIntoView({ behavior: 'smooth' });
                        setTimeout(() => {
                            window.location.hash = "#recrutement";
                            this.state.pathnameHash = window.location.pathname + window.location.hash;
                        }, 500);
                    }
                });
            });
        }
    }
    toggleServicesVisibility() {
        this.state.servicesVisibility = !this.state.servicesVisibility;
    }
    toggleNavVisibility() {
        this.state.navVisibility = !this.state.navVisibility;
    }
}

$ const links = [
    {
        href: "/",
        body: "Accueil",
        id: ""
    },
    {
        href: "/#nos-services",
        body: "Nos services",
        id: "nos-services-link"
    },
        {
        href: "/nos-services/realisation-de-formations",
        body: "Formations",
        id: ""
    },
    {
        href: "/qb24",
        body: "QB24",
        id: ""
    },
    {
        href: "/#recrutement",
        body: "Recrutement",
        id: "recrutement-link"
    },
]

<macro|{ href, body, id, test, onClick }| name="nav-link">
    <li on-click(onClick)>
        <a href=href class=`nav-a ${test}` id=id>
            ${body}
        </a>
    </li>
</macro>

<header class=input.class>
    <max-width>
        <div class="row between-center header">
            <a href="/" class="lg-hidden">
                <img
                    src=(input.isNavOff
                        ? "/images/logos/Logo-Audit-Process.avif"
                        : "/images/logos/Logo-Audit-Process-White.webp")
                    alt="Logo de l'entreprise Audit Process"
                    class="nav-logo"
                >
            </a>
            <nav
                type="nav"
                class=`lg-row lg-between-center nav lg-nav ${state.navVisibility && "opened-nav"}`
            >
                <span class="close lg-hidden" on-click("toggleNavVisibility")>
                    &#10005;
                </span>
                <a href="/" class="hidden lg-block">
                    <img
                        src=(input.isNavOff
                            ? "/images/logos/Logo-Audit-Process.avif"
                            : "/images/logos/Logo-Audit-Process-White.webp")
                        alt="Logo de l'entreprise Audit Process"
                        class="nav-logo"
                    >
                </a>
                <ul class="row items-center nav-ul">
                    <for|link| of=links>
                        <nav-link href=link.href body=link.body id=link.id test=`${state.pathnameHash === link.href ? (state.pathnameHash === "/" ? "nav-a-index-selected" : "nav-a-selected") : ""}`/>
                    </for>
                </ul>
                <a href="/contactez-nous" class="nav-contact">
                    Contactez-nous
                </a>
                <ul class="nav-ul-mobile">
                    <nav-link href="/" body="Accueil" test="nav-a-mobile"/>
                    <nav-link href="/#nos-services" body="Nos services" test="nav-a-mobile" id="nos-services-link" onClick="toggleNavVisibility"/>
                    <!-- <ul class=`ul-services ${state.servicesVisibility ? "col" : "hidden"}`>
                        <for|service| of=info.services>
                            <li class="row">
                                <img src=service.icon alt=service.icon_alt class=""/>
                                <nav-link href=`/nos-services/${service.slug}` body=service.title test="services-a-mobile"/>
                            </li>
                        </for>
                    </ul>-->
                    <nav-link href="/qb24" body="QB24" test="nav-a-mobile" onClick="toggleNavVisibility"/>
                    <nav-link href="/#recrutement" body="Recrutement" id="recrutement-link" test="nav-a-mobile" onClick="toggleNavVisibility"/>
                    <nav-link href="/contactez-nous" body="Contactez-nous" test="nav-a-mobile"/>                                     
                </ul>
                <div class="audit-mobile">
                    <a href="/">
                        <img
                            src="/images/logos/Logo-Audit-Process.avif"
                            alt="Logo de l'entreprise Audit Process"
                            class="nav-logo"
                        >
                    </a>
                </div>
            </nav>
            <div class="row items-center nav-unicode lg-hidden">
                <span class="burger" on-click("toggleNavVisibility")>
                    &equiv;
                </span>
            </div>
        </div>
    </max-width>
</header>

style {
    header {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        z-index: 2;
        background-color: white;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    }
    .header-index {
        box-shadow: none;
        background-color: transparent;
        transition: 200ms;
    }
    .header {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        padding-inline: 16px;
        font-size: 18px;
        height: 68px;
    }
    .audit-mobile {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .audit-mobile .nav-logo {
        width: 200px;
        height: auto;
        margin: 0 auto;
    }
    .nav-logo {
        height: 44px;
    }
    .ul-services {
        margin-top: -12px;
        padding-inline: 24px;
        gap: 12px;
    }
    .nav {
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        transition-duration: 300ms;
        z-index: 3;
        transform: translateX(100%);
        height: 100vh;
        background-color: white;
    }
    .opened-nav {
        transform: translateX(0%);
    }
    .close {
        color: black;
        font-size: 40px;
        position: absolute;
        top: 12px;
        right: 12px;
    }
    .nav-unicode {
        color: black;
        font-size: 44px;
    }
    .nav-unicode-index {
        color: white;
    }
    .nav-ul {
        display: none;
    }
    .burger-index:active {
        color: white;
        background-color: white;
        background-opacity: 30%;
    }
    .nav-contact {
        display: none;
    }
    .nav-contact-index {
        border: solid 2px white;
        color: white;
    }
    .nav-contact:hover {
        background-color: #025fae;
        color: white;   
    }
    .nav-contact-index:hover {
        color: #025fae;
        background-color: white;
    }
    .nav-ul-mobile {
        font-size: 24px;
        line-height: 32px;
        margin-top: 160px;
        padding: 0 24px 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }
    .services-a-mobile {
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        color: black;
    }
    .nav-a-mobile {
        width: 100%;
        text-decoration: none;
        color: black;
    }
    .nav-ul-mobile img {
        height: 40px;
    }
    .nav-ul-mobile li {
        padding: 12px;
        text-align: center;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .nav-ul-mobile li:active {
        border-radius: 20px;
        background: rgb(240, 240, 240);
    }
    @media (min-width: 640px) {
        .header {
            padding-inline: 24px;
        }
        .nav {
            width: 640px;
            box-shadow: -5px 0 8px -3px rgba(0, 0, 0, 0.3);    
        }
    }
    @media (min-width: 768px) {
        .header {
            padding-inline: 28px;
        }
    }
    @media (min-width: 1024px) {
        .nav-ul-mobile {
            display: none;
        }
        .audit-mobile {
            display: none;
        }
        .nav-ul {
            display: flex;
            list-style: none;
            gap: 12px;
        }
        .header {
            padding-inline: 32px;
        }
        .lg-hidden {
            display: none;
        }
        .nav {
            transition-duration: 0ms;
            position: static;
            border-left: none;
            z-index: 0;
            transform: translateX(0%);
            width: 100%;
            background-color: transparent;
            box-shadow: none;
            height: 70px;
        }
        .nav-a {
            padding: 0 12px 21px 12px;
            text-decoration: none;
            height: 68px;
            color: #6b7280;
            cursor: pointer;
        }
        .nav-a:hover {
            border-bottom: solid 2px #94a3b8;
            color: #1f2937;
        }
        .nav-a-index {
            color: white;
        }
        .nav-a-selected {
            color: #025fae;
            border-bottom: solid 2px #025fae;
        }
        .nav-a-index:hover {
            color: white;
            border-bottom: solid 2px white;
        }
        .nav-a-index-selected {
            color: white;
            border-bottom: solid 2px white;
        }
        .nav-contact {
            display: block;
            padding: 9px 12px;
            text-decoration: none;
            border-radius: 8px;
            transition-duration: 200ms;
            color: #025fae;
            border-color: #025fae;
            border: solid 2px #025fae;  
        }
        .nav-contact-index {
            border: solid 2px white;
            color: white;
        }
        .nav-contact:hover {
            background-color: #025fae;
            color: white;
        }
        .nav-contact-index:hover {
            color: #025fae;
            background-color: white;
        }
    }
    @media (min-width: 1280px) {
        .header {
            padding-inline: 0;
        }
    }
}
