<div class=`auto ${input.max || "xl"}`>
    <${input.renderBody}/>
</div>

style {
    .auto {
        margin-inline: auto;
    }
    .md {
        max-width: 768px;
    }
    .lg {
        max-width: 1024px;
    }
    .xl {
        max-width: 1280px;
    }
}
